import { ethers } from "ethers";
import { ABI_STAKE } from "./contracts/stakeABI";
import { config } from "../config";

export const getMinAmount = (signer) => {
    return new Promise(async (resolve, reject) => {
        const contract = new ethers.Contract(config.stakingAddress, ABI_STAKE, signer);
        const data = await contract.minAmount();
        const formattedData = ethers.utils.formatUnits(data, 18);
        resolve(formattedData);
    });
};

export const depositTokenFun = (lockIndex, signer, amount) => {
    return new Promise(async (resolve, reject) => {
        try {
            const contract = new ethers.Contract(
                config.stakingAddress,
                ABI_STAKE,
                signer,
            );
            const value = ethers.utils.parseEther(`${amount}`);
            let result;
            await contract
                .deposit(value, lockIndex)
                .then((r) => {
                    result = r;
                    console.log("%c Line:62 🍔 r", "color:#4fff4B", result);
                })
                .catch((err) => {
                    console.log("%c Line:67 🌶 err", "color:#e41a6a", err);
                });
            await result.wait();
            resolve(result);
        } catch (error) {
            console.log("%c Line:62 🍬 error", "color:#465975", error);
            resolve(false);
        }
    });
};

export const withdrawTokenFun = (signer, amount) => {
    return new Promise(async (resolve, reject) => {
        try {
            const contract = new ethers.Contract(
                config.stakingAddress,
                ABI_STAKE,
                signer,
            );
            const value = ethers.utils.parseEther(`${amount}`);
            let result;
            await contract
                .withdraw(value)
                .then((r) => {
                    result = r;
                    console.log("%c Line:62 🍔 r", "color:#4fff4B", result);
                })
                .catch((err) => {
                    console.log("%c Line:67 🌶 err", "color:#e41a6a", err);
                });
            await result.wait();
            resolve(result);
        } catch (error) {
            console.log("%c Line:62 🍬 error", "color:#465975", error);
            resolve(false);
        }
    });
};

export const userClaimableToken = (signer, address) => {
    return new Promise(async (resolve, reject) => {
        const contract = new ethers.Contract(config.stakingAddress, ABI_STAKE, signer);
        const data = await contract.getPendingDivs(address);
        const formattedData = ethers.utils.formatUnits(data, 18);
        resolve(formattedData);
    });
};

export const TotalStackers = (signer) => {
    return new Promise(async (resolve, reject) => {
        const contract = new ethers.Contract(config.stakingAddress, ABI_STAKE, signer);
        const data = await contract.getNumberOfHolders();
        resolve(data * 1);
    });
};

export const getUserStakingDetailsFun = (signer, address) => {
    return new Promise(async (resolve, reject) => {
        const contract = new ethers.Contract(config.stakingAddress, ABI_STAKE, signer);
        const data = await contract.staking(address);
        let formattedData = {
            stakingAmount : ethers.utils.formatUnits(data.amount, 18),
            stakingTime: ethers.utils.formatUnits(data.stakingTime, 0),
            lastClaimedTime: ethers.utils.formatUnits(data.lastClaimedTime, 0),
            lockTime: ethers.utils.formatUnits(data.lockTime, 0),
        }
        resolve(formattedData);
    });
};

export const getCurrentEpochFun = (signer) => {
    return new Promise(async (resolve, reject) => {
        const contract = new ethers.Contract(config.stakingAddress, ABI_STAKE, signer);
        const data = await contract.lastEpochTime();
        resolve(ethers.utils.formatUnits(data, 0));
    });
};

export const getTotalInvested = (signer) => {
    return new Promise(async (resolve, reject) => {
        const contract = new ethers.Contract(config.stakingAddress, ABI_STAKE, signer);
        const data = await contract.totalStaked();
        const formattedData = ethers.utils.formatUnits(data, 18);
        resolve(formattedData);
    });
};

export const getRewardRate = (signer, address) => {
    return new Promise(async (resolve, reject) => {
        const contract = new ethers.Contract(config.stakingAddress, ABI_STAKE, signer);
        const data = await contract.rewardRate();
        const formattedData = ethers.utils.formatUnits(data, 0);
        resolve(formattedData);
    });
};