import React from "react"
import {Route, HashRouter as Router, Routes} from "react-router-dom"
import {PageLoader} from "./components/PageLoader"
import Header from "./components/Header"
import {useTheme} from "./components/ThemeContext"
import Footer from "./components/Footer"
import Swap from "./pages/swap"

// import PrivateRoute from './PrivateRoute';
const Dashboard = React.lazy(() => import("./pages/Dashboard"))
const BLit = React.lazy(() => import("./pages/bLit"))
const BShare = React.lazy(() => import("./pages/bShare"))
const Presale = React.lazy(() => import("./pages/presale"))
const Earn = React.lazy(() => import("./pages/Earn"))
const ComingSoon = React.lazy(() => import("./pages/coming"))
const ProjectRoutes = () => {
  const {isDarkMode} = useTheme()

  return (
    <div className={isDarkMode ? "dark-theme" : "light-theme"}>
      <React.Suspense fallback={<PageLoader />}>
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/swap/:id" element={<Swap />} />
            <Route path="/swap" element={<Swap />} />
            <Route path="/bLit" element={<BLit />} />
            <Route path="/bShare" element={<BShare />} />
            {/* <Route path="/presale" element={<Presale />} /> */}
            <Route path="/Earn" element={<Earn />} />
            <Route path="/coming-soon" element={<ComingSoon />} />
          </Routes>
          <Footer />
        </Router>
      </React.Suspense>
    </div>
  );
}
export default ProjectRoutes
