import React, {useState, useEffect} from "react"

const CountDown = ({startDate}) => {
  const calculateTimeElapsed = () => {
    const difference = +new Date() - +new Date(startDate)
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    }
  }

  const [timeElapsed, setTimeElapsed] = useState(calculateTimeElapsed())

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeElapsed(calculateTimeElapsed())
    }, 1000)

    return () => clearInterval(timer)
  }, [startDate])

  return (
    <div className="flex gap-4">
      <span>{timeElapsed.days} days </span>
      <span>{timeElapsed.hours} hours </span>
      {/* <span>{timeElapsed.minutes} minutes </span> */}
      {/* <span>{timeElapsed.seconds} seconds </span> */}
    </div>
  )
}

export default CountDown
