import React, { useEffect, useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Switch,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink, useLocation } from "react-router-dom";
import "./Header.scss";
import { ConnectWallet } from "@thirdweb-dev/react";
import Logo_White from "./Logo_White.png";
import social1 from "../../images/social/social1.png";
import social2 from "../../images/social/social2.png";
import social3 from "../../images/social/social3.png";
import { useTheme } from "../ThemeContext";
import SwitchButton from "../SwitchButton";
import social1w from "../../images/social/social1-w.png";
import social2w from "../../images/social/social2-w.png";
import social3w from "../../images/social/social3-w.png";
import DrawerRight from "../shared/DrawerRight/DrawerRight";
const MobileHeader = ({ MenuList }) => {
  const { isDarkMode } = useTheme();

  const location = useLocation();
  const { hash, pathname, search } = location;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    toggleDrawer(false);
    // setOpen(false)
  }, [pathname]);

  const toggleDrawer = (open) => (event) => {
    console.log("open", open + " 000 " + event);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
  };

  // Right Drawer
  const [openRightDrawer, setOpenRightDrawer] = React.useState({
    right: false,
  });

  const toggleDrawerRight = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenRightDrawer({ ...openRightDrawer, [anchor]: open });
  };

  // console.log("open", open + ' ++ ' + pathname)
  return (
    <div className="mobile-header mobile-view">
      <div className="flex justify-between">
        <div className="p-3">
          <SwitchButton />
        </div>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          // onClick={toggleDrawer(true)}
          onClick={toggleDrawerRight("right", true)}
          sx={{ mr: 2, color: "white" }}
        >
          <MenuIcon />
        </IconButton>
      </div>

      <DrawerRight
        toggleDrawer={toggleDrawerRight}
        openRightDrawer={openRightDrawer}
        content={
          <div
            className={`drawer-content px-4 h-full flex flex-col justify-between pb-4  ${
              isDarkMode ? "dark-theme" : "light-theme"
            }`}
          >
            <List>
              {/* {['Home', 'About', 'Services', 'Contact'].map((text, index) => (
              <ListItem button key={text}>
                <ListItemText primary={text} />
              </ListItem>
            ))} */}
              {MenuList.map((db) => {
                return (
                  <NavLink
                    className={({ isActive, isPending }) =>
                      isPending ? "pending" : isActive ? "active" : ""
                    }
                    to={db.link}
                    onClick={toggleDrawerRight("right", false)}
                  >
                    {db.title}
                  </NavLink>
                );
              })}
            </List>

            <div className="flex flex-col justify-start gap-10 ">
              <img src={Logo_White} className="w-10" />
              {/* <div className="dark-social  flex items-center gap-6">
                <img src={social1} />
                <img src={social2} />
                <a href="https://t.me/Baselitofficial" target="_blank">
                  <img src={social3} />
                </a>
              </div>
              <div className="light-social flex items-center gap-6">
                <img src={social1w} />
                <img src={social2w} />
                <a href="https://t.me/Baselitofficial" target="_blank">
                  <img src={social3w} />
                </a>
              </div> */}
              <ConnectWallet className="connect-wallet" />
            </div>
          </div>
        }
      />

      {/* <Drawer
                anchor="right"
                open={open}
                onClose={toggleDrawer(false)}
                className="drawer-content "
            >
                <div className={`flex flex-col justify-between h-full pb-3 px-4 ${isDarkMode ? "dark-theme" : "light-theme"}`}>
                    <div
                        role="presentation"
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}
                        className="mb-6"
                    >
                        <List>
                          
                            {MenuList.map((db) => {
                                return (
                                    <NavLink
                                        className={({ isActive, isPending }) =>
                                            isPending ? "pending" : isActive ? "active" : ""
                                        }
                                        to={db.link}
                                    // onClick={toggleDrawer(true)}
                                    >
                                        {db.title}
                                    </NavLink>
                                );
                            })}

                        </List>


                    </div>
                    <div className="flex flex-col justify-start gap-10">
                        <img src={Logo_White} className="w-10" />
                        <div className="dark-social  flex items-center gap-6">
                            <img src={social1} />
                            <img src={social2} />
                            <a href="https://t.me/Baselitofficial" target="_blank">
                                <img src={social3} />
                            </a>
                        </div>
                        <div className="light-social flex items-center gap-6">
                            <img src={social1w} />
                            <img src={social2w} />
                            <a href="https://t.me/Baselitofficial" target="_blank">
                                <img src={social3w} />
                            </a>
                        </div>
                        <ConnectWallet className="connect-wallet" />
                    </div>
                </div>
            </Drawer> */}
    </div>
  );
};

export default MobileHeader;
