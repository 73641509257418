import React, {useState} from "react"
import {Text16, Text24, Text36} from "../../components/Text"
import bLIT_bShare from "../../images/icon/Logo_White.png"
import {useNavigate} from "react-router-dom"
import Countdown from "react-countdown"

const LaunchTime = ({closeModal}) => {
  const navigate = useNavigate()
  return (
    <>
      {/* Head */}
      <div className="flex justify-center mb-6">
        <div></div>
        <div className="flex items-center gap-3">
          <img src={bLIT_bShare} />
          {/* <Text24>bLIT</Text24> */}
        </div>
      </div>
      {/* <Card className={`flex justify-between p-3 px-4 mb-6`}> */}
      <div className="text-center mb-6">
        <p className="text-[40px] sm:text-[48px] text-white font-bold">
          Launch In <Countdown date={Number(1711044000 * 1000)} />
        </p>
      </div>
      {/* <div className="text-center mb-6" onClick={() => navigate("/")}>
        <button className="bg-[#6175E8] rounded-xl p-[10px] sm:text-sm text-[20px] text-white">
          Go to Dashboard
        </button>
      </div> */}

      {/* </Card> */}

      {/* <Tabs categories={categories} /> */}
    </>
  );
}
export default LaunchTime
