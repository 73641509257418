import { ethers } from "ethers";
import { ABI_SWAP } from "./contracts/swapAbi";
import {config} from "../config";

export const mintTokenFun = (referral, mint, saleAddress, signer, amount) => {
    return new Promise(async (resolve, reject) => {
        try {
            const contract = new ethers.Contract(saleAddress, ABI_SWAP, signer);
            const value = mint ? ethers.utils.parseUnits(`${amount}`, 6) : ethers.utils.parseEther(`${amount}`);
            let result;
            let ref;
            referral === undefined ? ref = config.defaultReferral : ref = referral;
            await contract
                .swap(ref, value, mint)
                .then((r) => {
                    result = r;
                    console.log("%c Line:62 🍔 r", "color:#4fff4B", result);
                })
                .catch((err) => {
                    console.log("%c Line:67 🌶 err", "color:#e41a6a", err);
                });
            await result.wait();
            resolve(result);
        } catch (error) {
            console.log("%c Line:62 🍬 error", "color:#465975", error);
            resolve(false);
        }
    });
};
