import React from 'react'
import { Switch } from "@headlessui/react";
import { useTheme } from '../ThemeContext';

const SwitchButton = () => {
  const { isDarkMode, toggleTheme } = useTheme();

    return (
      <Switch
        checked={isDarkMode}
        onChange={toggleTheme}
        className={`${isDarkMode ? "bg-white" : "bg-[#0D0D0D]"
          } relative inline-flex h-6 w-11 items-center rounded-full`}
      >
        <span className="sr-only">Enable notifications</span>
        <span
          className={`${isDarkMode ? "translate-x-6" : "translate-x-1"
            } inline-block h-4 w-4 transform rounded-full bg-[#6178EA] transition`}
        />
      </Switch>
    )
  }

export default SwitchButton
