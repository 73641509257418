import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import Container from '../Container'

const Footer = () => {
    const footerLink = [
      {
        title: "Twitter",
        link: "https://twitter.com/baselitofficial",
      },
      {
        title: "Telegram ",
        link: "https://t.me/Baselitofficial",
      },
      {
        title: "Tiktok",
        link: "https://www.tiktok.com/@baselitofficial",
      },
      {
        title: "Instagram ",
        link: "https://www.instagram.com/baselitofficial?igsh=MXV0MHI5Z2d3OWVsMw%3D%3D",
      },
      // {
      //     title: 'Email',
      //     link: ''
      // },
      // {
      //     title: 'Dextools',
      //     link: ''
      // },
      // {
      //     title: 'Dextools ($bLit)',
      //     link: ''
      // },
      // {
      //     title: 'Privacy Policy',
      //     link: ''
      // },
      // {
      //     title: 'Terms and Conditions',
      //     link: ''
      // },
    ];
    return (
        <Container>
            <div className='flex flex-wrap items-center justify-center sm:gap-3 gap-5 pb-6 pt-10'>
                {footerLink.map((db) => (
                    <Link to={db.link} className='underline sm:text-xs text-[20px]'>{db.title}</Link>
                ))}
            </div>
        </Container>
    )
}

export default Footer
