import React from "react";

export function Text14({ className, children, color }) {
  return (
    <p
      className={` text-sm  leading-6 ${className}`}
    >
      {children}
    </p>
  );
}
export function Text16({ className, children, color }) {
  return (
    <p
      className={` text-base  leading-6 ${className}`}
    >
      {children}
    </p>
  );
}
export function Text1620({ className, children, color }) {
  return (
    <p
      className={` text-base lg:text-xl  ${className}`}
    >
      {children}
    </p>
  );
}
export function Text18({ className, children, color }) {
  return (
    <h6
      className={` text-lg  leading-[26px] ${className}`}
    >
      {children}
    </h6>
  );
}
export function Text20({ className, children, color }) {
  return (
    <h5 className={`${className}  text-xl  `}>
      {children}
    </h5>
  );
}
export function Text24({ className, children, color }) {
  return (
    <h2
      className={`  leading-7 lg:leading-8 text-base lg:text-2xl ${className}`}
    >
      {children}
    </h2>
  );
}
export function Text32({ className, children, color }) {
  return (
    <h1
      className={` text-2xl lg:text-[32px] leading-8 lg:leading-10 font-bold ${className}`}
    >
      {children}
    </h1>
  );
}
export function Text36({ className, children, color }) {
  return (
    <h1
      className={` text-2xl lg:text-[36px] leading-8 lg:leading-10 font-bold ${className}`}
    >
      {children}
    </h1>
  );
}
export function TextSky({ className, children }) {
  return <span className={`text-turnary-800  ${className}`}>{children}</span>;
}
export function TextPink({ className, children }) {
  return <span className={`text-pink-800  ${className}`}>{children}</span>;
}
