export const Card = ({ children, className }) => {
    return (
      <div className={`border-2 border-[#6178EA] rounded-[20px] p-2 shadow-xl ${className}`}>{children}</div>
    );
  };

  export const Card64 = ({ children, className }) => {
    return (
      <div className={`xs:py-12 sm:py-12 md:py-12 lg:py-16 ${className}`}>{children}</div>
    );
  };

  
  export const BorderBox = ({ children, className }) => {
    return (
      <div className={`border-[1px] border-solid border-grey-200  ${className}`}>{children}</div>
    );
  };
