export const config = {
    saleAddress: "0x54dBa685CC654B225fD2b79359D5152007c7d763",
    price: 300,
    stakingAddress: "0x83f30B468e61A28DCD1b7CA0b774Bb75e6D5b74d",
    boardRoomAddress: "0x5eb7a2b902c64803cac2442b69890adda5797920",
    swapAddress: "0x88b7fCA69D8cA29005e3b5A8e90FAcF0baaA9C3c",
    InfuraUrl: "https://rpc.pulsechain.com/",
    defaultReferral: "0x730Fd1E5Ab9CDBb1E27C8d93Bc9F6ffe0079A6F4",
    tokens: {
        USDC: {
            address: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
            decimal: 6,
        },
        BLIT: {
            address: "0x88b7fCA69D8cA29005e3b5A8e90FAcF0baaA9C3c",
            decimal: 18,
        },

        BSHARE: {
            address: "0xF9E6fb8A7C482b18A43e2ab00eD6e40f15fC9d0E",
            decimal: 18
        }
    },
    stakingTokens: {
        PE: {
            address: "0xdcDCF68b5B1Dc051c2237b0C92cB7df000FD9197",
            priceAddress: "0xdcDCF68b5B1Dc051c2237b0C92cB7df000FD9197",
            stakeAddress: "0xE881e99623787680DbbC0ecc65000a7D4E859514",
            decimal: 18,
            income: 1,
            type: "TOKEN",
            BuyLink:
                "https://app.pulsex.com/swap?outputCurrency=0xdcDCF68b5B1Dc051c2237b0C92cB7df000FD9197",
        },
        PELP: {
            address: "0x1515d879556ae70a76154b505804f9a29afb4cfd",
            priceAddress: "0x1515d879556ae70a76154b505804f9a29afb4cfd",
            stakeAddress: "0x8B2151955f60Bf30c6bCd17ee9a5D30b04c48b2B",
            decimal: 18,
            income: 0.5,
            type: "LP",
            BuyLink:
                "https://v2-app.pulsex.com/add/0xdcDCF68b5B1Dc051c2237b0C92cB7df000FD9197/0xF052BdA1AAf96deE984BaBc10e9f3424930f2E9c",
        },
    },
};
